import axios from 'axios';
import tools from "@/composables/tools";
import { User } from '@/interfaces/User'
import { Clase } from '@/interfaces/Clase'
import { alertController } from '@ionic/core';
import { mainStore } from '@/stores/mainStore';
import { Asiento } from '@/interfaces/Asiento';

export abstract class UserService {

  static async search(item: string, sesion_id: number | undefined): Promise<User[]> {
    if (item.length < 3) return []
    try {
      const response = await axios.get('galidancia/searchAlumnos', {
        params: {
          item: item,
          sesion_id: sesion_id
        }
      })
      return response.data
    } catch (error) {
      tools().toast('No se pueden cargar los alumnos', 'danger', 5000)
      return []
    }
  }

  /*
  * Lanza una busqueda para lanzar mensajes por whatsapp
   */
  static async search2WhatsappLauncher(filter: object, page: number): Promise<User[]> {
    try {
      const response = await axios.get('galidancia/search2WhatsappLauncher', {
        params: {
          filter: filter,
          page: page
        }
      })
      return response.data
    } catch (error) {
      tools().toast('No se pueden cargar los resultados de la busqueda', 'danger', 5000)
      return []
    }
  }


  static async checkAlertas() {
    //si en la url hay la palabra salvoconducto, no se lanzan las alertas para dar la oportunidad de poder correjir un problema si que este saliendo constantemente la alerta
    if (location.href.indexOf('noAlerts') > -1) return

    try {
      const response = await axios.get('checkAlertas');

      if (response.data.length > 0) {
        // Verifica si el alert ya está abierto
        if (!mainStore().isAlertOpen) {
          // Marca que el alert está abierto
          mainStore().isAlertOpen = true;

          const presentAlert = async () => {
            const alert = await alertController.create({
              header: response.data[0].title,
              subHeader: undefined,
              message: response.data[0].msg,
              backdropDismiss: false,  // No permitir cerrar al hacer clic fuera
              buttons: [{
                text: response.data[0].btn.text,
                handler: async () => {
                  await tools().setLog('Pulsa alerta ' + response.data[0].btn.text);
                  location.href = response.data[0].btn.url;
                  mainStore().isAlertOpen = false; // Marcar como cerrado al presionar OK
                }
              }],
            });

            await tools().setLog('Se muestra alerta ' + response.data[0].msg);
            await alert.present();

            // Asegurar que isAlertOpen se resetea si el alert se cierra de otra manera
            alert.onDidDismiss().then(() => {
              mainStore().isAlertOpen = false;
            });
          };

          await presentAlert();
        }
      }

      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching obligaciones:', error);
      return [];
    }
  }



  static async create(user: User): Promise<User> {
    try {
      const response = await axios.post('user', user)
      tools().toast('Usuario craedo correctamente', 'success', 5000)
      return response.data
    } catch (error) {
      tools().toast('No se ha podido crear el usuario', 'danger', 5000)
      return {} as User
    }

  }

  static async setWhatsappSent(
    recept_user_id: number,
    codigo: string,
    freecol_int_1: number,
    text: string,
    tlf: string
  ) {
    const response = await axios.post('entidad/setWhatsappSent', {
      recept_user_id: recept_user_id,
      codigo: codigo,
      freecol_int_1: freecol_int_1,
      text: text,
      tlf: tlf
    })
  }

  static async getMisClases(user_id: number): Promise<Clase[]> {
    try {
      const response = await axios.get('user/clases/' + user_id)
      return response.data
    } catch (error) {
      return []
    }
  }

  static async getAsientos(user_id: number): Promise<Asiento[]> {
    try {
      const response = await axios.get('asientosByAlumno/' + user_id)
      return response.data
    } catch (error) {
      return []
    }
  }


}
