import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router';
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import tools from "@/composables/tools";
import pkg from '../package.json'; // Asegúrate de que tu tsconfig permita importar JSON


import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';


import { currentUserStore } from '@/stores/currentUser'
import { User } from '@/interfaces/User';
import { useProfesorStore } from './stores/profesorStore';
import { useCoordinadorStore } from './stores/coordinadorStore';
import { useAulaStore } from './stores/aulaStore';
import { mainStore } from './stores/mainStore';
import userAuth from './composables/userAuth';
import { UserService } from './services/UserService';


const pinia = createPinia()


const app = createApp(App)
  .use(IonicVue)
  .use(pinia)
  .use(VueAxios, axios)
  .use(router);

moment.locale('es');

app.config.globalProperties.$filters = {
  timeAgo(date: string) {
    return moment(date).fromNow()
  },
  human(date: string) {
    let val = moment(date).format('dddd D')
    val += ' de '
    val += moment(date).format('MMMM')
    return val
  },
}


router.isReady().then(() => {
  app.mount('#app');
});

router.beforeEach((to) => {
  if (to.meta.requiredAuth && localStorage.getItem("token") === null) {
    currentUserStore().$state.redirectAfterLogin = to.fullPath
    router.push({ path: "/login" });
  }
  return true
})


axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL
axios.defaults.headers.common["Accept"] = "application/json"
if (localStorage.getItem("token"))
  axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");

//hacemos un interceptor que derive a login cada vez que haya un error 401 no autenticado
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      console.log(error.response)
      tools().toast(error.response.data.message, 'danger')
      router.push({ path: "/login" });
    }
    else {
      tools().toast(error.response.data.message, 'danger', 10000)
    }
    return Promise.reject(error);
  });



async function initializeApp() {
  if (currentUserStore().$state.user === null) {
    const myUser = await userAuth().getCurrentUser();
    console.log(myUser)
    currentUserStore().update(myUser);
    //actualizamos en localsotrage el usuario
    localStorage.setItem("user", JSON.stringify(myUser));
  }
  useProfesorStore().updateProfesores();
  useCoordinadorStore().updateCoordinadores();
  useAulaStore().updateAulas();
}

//cada 1 minuto vamos a comprobar si hay alertas pendientes
setInterval(() => {
  UserService.checkAlertas()
}, 60 * 1000);

initializeApp();

UserService.checkAlertas()

async function checkVersion() {
  try {
    const response = await axios.get('/gest_galidancia_com_version');
    const latestVersion = response.data; // Ajusta si la respuesta tiene una propiedad "version"
    const localVersion = pkg.version; // La versión local de package.json

    if (latestVersion !== localVersion) {
      tools().setLog('Nueva versión disponible: ' + latestVersion + ' Actualmente estás en la versión ' + localVersion + ' Debes actualizar la aplicación');
      //alert(`Nueva versión disponible: ${ latestVersion }.Actualmente estás en la versión ${ localVersion }.Debes actualizar la aplicación`);
      //window.location.href = '/'; // Forzar actualización del navegador
    }
  } catch (error) {
    console.error('Error al obtener la versión', error);
  }
}

// Ejecutar la verificación de versión inmediatamente
checkVersion();

// Programar la verificación cada 10 minutos
setInterval(checkVersion, 10 * 60 * 1000);
